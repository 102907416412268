<template>
  <div class="transport-info main-block">
    <div class="transport-info__box main-block__head">
      <h1 class="transport-info__box-title main-block__title">Информация</h1>
      <a v-if="allCarsData.tracker && allCarsData.tracker.id" :href="'/geozone?id='+allCarsData.tracker.id">Геозона</a>
    </div>
    <ul v-if="allCarsData" class="transport-info__items">
      <li class="transport-info__item flex">
        <p class="transport-info__key">Текущий статус</p>
        <button :class="statuses[allCarsData.status]">{{ status(allCarsData.status) }}</button>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">Транспорт</p>
        <p class="transport-info__value">
          {{ allCarsData.model.name }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">Номер машины</p>
        <p class="transport-info__value">
          {{ allCarsData.number }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">VIN код</p>
        <p class="transport-info__value">
          {{ allCarsData.registration ?? '-' }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">Вид</p>
        <p class="transport-info__value">
          {{ translateBody(allCarsData.body) ?? '-' }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">Год выпуска</p>
        <p class="transport-info__value">
          {{ allCarsData.car_date.split(" ")[0].split("-")[0] }}
        </p>
      </li>
      <li v-if="allCarsData.characteristics[0].temp" class="transport-info__item flex">
        <p class="transport-info__key">Температурный режим</p>
        <p class="transport-info__value">
          {{ allCarsData.characteristics[0].temp }} °С
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">
          Город
        </p>
        <p class="transport-info__value">
          {{ allCarsData.city_id?.name }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">
          Страховая компания
        </p>
        <p class="transport-info__value">
          {{ insurance.compulsory_company ?? '-' }}
        </p>
      </li>
      <li class="transport-info__item flex">
        <p class="transport-info__key">
          Срок страхования
        </p>
        <p class="transport-info__value">
          {{ insurance && insurance.compulsory_expired_at ? 'до ' + new Date(insurance.compulsory_expired_at).toLocaleDateString('ru-RU', { day:"numeric", month:"numeric", year: "numeric"}) + ' г.' : '-' }} 
        </p>
      </li>
      
    </ul>
  </div>
</template>

<script>
import transportBodyMixin from "../../mixins/transportBody";
export default {
  mixins: [transportBodyMixin],
  props: {
    allCarsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      statuses: {
        free: "table-btn__status",
        onway: "table-btn__status-active table-btn__status",
        rent: 'table-btn__status-active table-btn__status',
        busy: 'table-btn__status-busy',
        pending: 'table-btn__status-pending',
        repair: 'table-btn__status-busy'
      },
      insurance: {},
    }
  },
  methods: {
    status(value) {
      if (value === "free") {
        return "Свободен";
      }
      else if (value === "busy") {
        return "Занят";
      }
      else if (value === "pending") {
        return "Ожидание";
      }
      else if (value === "rent") {
        return "Аренда";
      }
      else if (value === "repair") {
        return "Ремонт";
      }
      else if (value === "onway") {
        return "В пути";
      }
    },
    async infoInsurance() {
      this.$api
        .get("/web/insurance/" + this.allCarsData.id)
        .then((response) => {
          this.insurance = response.data
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, попробуйте позднее!');
        });
    },
  },
  async mounted() {
    await this.infoInsurance();
  }
};
</script>

<style scoped>
.transport-info__items {
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 3.5px 0 0 0;
}
.transport-info__item {
  padding-top: 11.5px;
}
.transport-info__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}
.transport-info__value {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #000000;
}
.transport-info__key {
  text-align: start;
}
.transport-info__box-icon{
  background: inherit;
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FFF4D2;
  color: #FF9E0D;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FBD2CE;
  color: #E74C3C;
}
</style>
